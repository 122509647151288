import * as z from "zod";
import { isValidCpf, isValidDate, isOver18 } from "utils";

export const cpfSchema = z
  .string({ required_error: "CPF obrigatório" })
  .min(1, { message: "CPF obrigatório" })
  .refine(isValidCpf, { message: "CPF inválido" });

export const schemaPersonalInfo = z
  .object({
    cpf_cnpj: cpfSchema,
    nome: z
      .string({ required_error: "Nome obrigatório" })
      .min(5, { message: "Nome deve ter no mínimo 5 caracteres" }),
    dtnasc: z
      .string({ required_error: "Data de nascimento obrigatório" })
      .min(1, "Data de nascimento obrigatório"),
    email: z
      .string({ required_error: "Email obrigatório" })
      .email("Email inválido"),
    emailConfirm: z
      .string({ required_error: "Email obrigatório" })
      .email("Email inválido"),
    rg: z.string().min(5, { message: "RG obrigatório" }),
    emissor: z.string().min(1, { message: "Órgão emissor obrigatório" }),
    celular: z
      .string({ required_error: "Telefone obrigatório" })
      .min(15, { message: "Telefone deve conter no mínimo 15 caracteres" }),
    nacionalidade: z.string().min(1, { message: "Nacionalidade obrigatória" }),
    profissao: z
      .string({ required_error: "Profissão obrigatória" })
      .min(5, { message: "Profissão deve ter no mínimo 5 caracteres" }),
    estadocivil: z.string().min(1, { message: "Estado civil obrigatório" }),
    needPassword: z.boolean().optional(),
    logindistribuidora: z.string().optional(),
    senhadistribuidora: z.string().optional(),
    nomeChecked: z.boolean().default(false),
    dtnascChecked: z.boolean().default(false),
    rgChecked: z.boolean().default(false),
    emissorChecked: z.boolean().default(false),
  })
  .superRefine((values, ctx) => {
    if (values.email !== values.emailConfirm) {
      ctx.addIssue({
        message: "Email diferente ao fornecido",
        code: z.ZodIssueCode.custom,
        path: ["emailConfirm"],
      });
    }

    if (values.dtnasc && !isValidDate(values.dtnasc)) {
      ctx.addIssue({
        message: "Data inválida",
        code: z.ZodIssueCode.custom,
        path: ["dtnasc"],
      });
    }

    if (values.dtnasc && !isOver18(values.dtnasc)) {
      ctx.addIssue({
        message: "Cliente precisa ser maior de 18 anos",
        code: z.ZodIssueCode.custom,
        path: ["dtnasc"],
      });
    }

    if (!values.logindistribuidora?.length && values.needPassword) {
      ctx.addIssue({
        message: "Usuário é obrigatório.",
        code: z.ZodIssueCode.custom,
        path: ["logindistribuidora"],
      });
    }

    if (!values.senhadistribuidora?.length && values.needPassword) {
      ctx.addIssue({
        message: "Senha é obrigatória.",
        code: z.ZodIssueCode.custom,
        path: ["senhadistribuidora"],
      });
    }

    if (!!values.nome.length && !values.nomeChecked) {
      ctx.addIssue({
        message: "Confirme o nome completo",
        code: z.ZodIssueCode.custom,
        path: ["nome"],
      });
    }

    if (!!values.dtnasc.length && !values.dtnascChecked) {
      ctx.addIssue({
        message: "Confirme a data de nascimento",
        code: z.ZodIssueCode.custom,
        path: ["dtnasc"],
      });
    }

    if (!!values.rg.length && !values.rgChecked) {
      ctx.addIssue({
        message: "Confirme o número de RG",
        code: z.ZodIssueCode.custom,
        path: ["rg"],
      });
    }

    if (!!values.emissor.length && !values.emissorChecked) {
      ctx.addIssue({
        message: "Confirme o órgão emissor",
        code: z.ZodIssueCode.custom,
        path: ["emissor"],
      });
    }
  });
