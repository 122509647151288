import * as z from "zod";

export const schemaEnergyProviderData = z
  .object({
    concessionaria: z.string().min(1, "Distribuidora de Energia obrigatória"),
    classificacao: z.string().min(1, "Tipo de instalação obrigatório"),
    energyBox: z.string().min(1, "Tipo de padrão obrigatório"),
    valorkwh: z.string().min(3, "Valor do Kwh obrigatório"),
    numcliente: z.string().optional(),
    valorcontribuicao: z.string().optional(),
    numinstalacao: z.string().min(3, "Número de instalação obrigatório"),
    concessionariaChecked: z.boolean().default(false),
    classificacaoChecked: z.boolean().default(false),
    energyBoxChecked: z.boolean().default(false),
    valorkwhChecked: z.boolean().default(false),
    numclienteChecked: z.boolean().default(false),
    valorcontribuicaoChecked: z.boolean().default(false),
    numinstalacaoChecked: z.boolean().default(false),
  })
  .superRefine((values, ctx) => {
    if (
      values.classificacao !== "Rural" &&
      Number(values.valorcontribuicao) < 3
    ) {
      ctx.addIssue({
        message: "Taxa de iluminação pública obrigatória",
        code: z.ZodIssueCode.custom,
        path: ["valorcontribuicao"],
      });
    }

    if (!!values.concessionaria.length && !values.concessionariaChecked) {
      ctx.addIssue({
        message: "Confirme a distribuidora de energia da conta",
        code: z.ZodIssueCode.custom,
        path: ["concessionaria"],
      });
    }

    if (!!values.classificacao.length && !values.classificacaoChecked) {
      ctx.addIssue({
        message: "Confirme a classificação da conta",
        code: z.ZodIssueCode.custom,
        path: ["classificacao"],
      });
    }

    if (!!values.energyBox.length && !values.energyBoxChecked) {
      ctx.addIssue({
        message: "Confirme o tipo do padrão",
        code: z.ZodIssueCode.custom,
        path: ["energyBox"],
      });
    }

    if (!!values.valorkwh.length && !values.valorkwhChecked) {
      ctx.addIssue({
        message: "Confirme o valor do kWh",
        code: z.ZodIssueCode.custom,
        path: ["valorkwh"],
      });
    }

    if (!!values.numcliente?.length && !values.numclienteChecked) {
      ctx.addIssue({
        message: "Confirme o número do cliente",
        code: z.ZodIssueCode.custom,
        path: ["numcliente"],
      });
    }

    if (
      !!values.valorcontribuicao?.length &&
      !values.valorcontribuicaoChecked
    ) {
      ctx.addIssue({
        message: "Confirme a taxa de iluminação pública",
        code: z.ZodIssueCode.custom,
        path: ["valorcontribuicao"],
      });
    }

    if (!!values.numinstalacao.length && !values.numinstalacaoChecked) {
      ctx.addIssue({
        message: "Confirme o número da instalação",
        code: z.ZodIssueCode.custom,
        path: ["numinstalacao"],
      });
    }
  });
