import { ConfigOption } from "features/connection-form/ConnectionForm.types";

/* eslint-disable @typescript-eslint/ban-ts-comment */
const UFSCALC = {
  MG: 0.1,
  MT: 0.1,
  MS: 0.1,
  RJ: 0.1,
  SP: 0.1,
  GO: 0.14,
  PE: 0.05,
};

interface CalculateEnergySavingsParams {
  edtvalorkwh: number;
  edtMes: number[];
  cmbtipoenergia: number;
  edtUF: "MG" | "MT" | "MS" | "RJ" | "SP" | "GO" | "PE";
}

interface CalculateEnergySavingsReturn {
  averageConsumption: number;
  saving: number;
}

function calculateEnergySavings({
  edtvalorkwh,
  edtMes,
  cmbtipoenergia,
  edtUF,
}: CalculateEnergySavingsParams): CalculateEnergySavingsReturn {
  const defaultReturn = { averageConsumption: 0, saving: 0 };

  if (!edtvalorkwh) return defaultReturn;

  const yearlyAverageConsumption =
    edtMes.reduce((acum, monthConsume) => acum + monthConsume, 0) /
    edtMes.length;

  const TaxaPublica = [30, 50, 100][Math.floor(cmbtipoenergia / 3)];

  const averageConsumption = yearlyAverageConsumption - TaxaPublica;

  const yearConsume = averageConsumption * 12;

  const saving = yearConsume * (edtvalorkwh + 0.2) * UFSCALC[edtUF];

  if (!averageConsumption || !saving) return defaultReturn;

  return { averageConsumption, saving };
}

function getLast12Months() {
  const monthNamesPT = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() - 1;
  const currentYear = currentDate.getFullYear();

  const last12Months = Array.from({ length: 12 }, (_, index) => {
    const monthIndex = (currentMonth - index + 12) % 12;
    const year = currentYear + Math.floor((currentMonth - index) / 12);
    const monthName = monthNamesPT[monthIndex];
    return `Consumo Kwh - ${monthName} ${year.toString()}`;
  });

  return last12Months;
}

const getMinimumRangeValue = (rangeValues: ConfigOption[]) => {
  return rangeValues.reduce((acc, range) => {
    if (!acc) return range.min;
    if (range.max > 1 && range.min < acc) return range.min;
    return acc;
  }, 0);
};

const getSupplierData = (rangeValues: ConfigOption[], consumption: number) => {
  return rangeValues.find((range) => {
    const isMinDefined = range.min !== undefined && range.min !== null;
    const isMaxDefined = range.max !== undefined && range.max !== null;

    if (!isMinDefined || !isMaxDefined) return;
    return (
      (range.min === 0 || consumption >= Number(range.min)) &&
      (range.max === 0 || consumption <= Number(range.max))
    );
  });
};

const calculateIfConsumptionIsAllowed = (
  range: ConfigOption[],
  availabilityTax: number,
  consumption: number
) => {
  const minValue = getMinimumRangeValue(range);
  const supplierData = getSupplierData(
    range,
    consumption < minValue ? minValue : consumption
  );

  const useAvailability = supplierData?.disponibilidade;

  const valueToCompare = useAvailability
    ? consumption
    : consumption + availabilityTax;

  return {
    isAllowed: valueToCompare >= minValue,
    supplier: supplierData,
    minValue: minValue,
  };
};

export {
  calculateEnergySavings,
  getLast12Months,
  calculateIfConsumptionIsAllowed,
};
