enum PowerCompanies {
  "CEMIG-MG" = "CEMIG-D_MG",
  "ENERGISA-MT" = "ENERGISA_MT",
  "ENERGISA-MS" = "ENERGISA_MS",
  "ENERGISA-SP" = "ENERGISA_SP",
  "ENERGISA-RJ" = "ENERGISA_RJ",
  "ENERGISA PB-PB" = "ENERGISA PB_PB",
  "EQUATORIAL-GO" = "EQUATORIAL_GO",
  "EQUATORIAL-AL" = "EQUATORIAL_AL",
  "EQUATORIAL-MA" = "EQUATORIAL_MA",
  "EQUATORIAL-PI" = "EQUATORIAL_PI",
  "CPFL-SP" = "CPFL_SP",
  "CEEE-RS" = "CEEE_RS",
  "EDP-SP" = "EDP_SP",
  "CPFL PIRATININGA-SP" = "CPFL PIRATININGA_SP",
  "CPFL SANTA CRUZ-SP" = "CPFL SANTA CRUZ_SP",
  "ENERGISA SUL SUDESTE-MG" = "ENERGISA SUL SUDESTE_MG",
  "ENERGISA SUL SUDESTE-PR" = "ENERGISA SUL SUDESTE_PR",
  "ENERGISA SUL SUDESTE-SP" = "ENERGISA SUL SUDESTE_SP",
  "ENERGISA MINAS RIO-MG" = "ENERGISA MINAS RIO_MG",
  "ENERGISA MINAS RIO-RJ" = "ENERGISA MINAS RIO_RJ",
  "ENERGISA TOCANTINS-TO" = "ENERGISA TOCANTINS_TO",
  "ELEKTRO-SP" = "ELEKTRO_SP",
  "NEO ENERGIA-PE" = "NEO ENERGIA_PE",
  "COSERN-RN" = "COSERN_RN",
  "COPEL-PR" = "COPEL_PR",
  "ENEL-CE" = "ENEL_CE",
  "ENEL-RJ" = "ENEL_RJ",
  "RGE-RS" = "RGE_RS",
  "CELESC-SC" = "CELESC_SC",
  "EDP-ES" = "EDP_ES",
}

enum Classification {
  RURAL = "Rural",
  RESIDENCIAL = "Residencial",
  INDUSTRIAL = "Industrial",
  COMERCIAL = "Comercial"
}

const POWER_COMPANIES_WITH_RULES: string[] = [
  PowerCompanies["CEMIG-MG"],
  PowerCompanies["ENERGISA-MT"],
  PowerCompanies["ENERGISA-MS"],
  PowerCompanies["ENERGISA PB-PB"],
  PowerCompanies["EQUATORIAL-GO"],
  PowerCompanies["EQUATORIAL-AL"],
  PowerCompanies["ENERGISA-SP"],
  PowerCompanies["CPFL-SP"],
  PowerCompanies["CPFL PIRATININGA-SP"],
  PowerCompanies["CPFL SANTA CRUZ-SP"],
  PowerCompanies["ENERGISA SUL SUDESTE-MG"],
  PowerCompanies["ENERGISA MINAS RIO-MG"],
  PowerCompanies["CEEE-RS"],
  PowerCompanies["ELEKTRO-SP"],
  PowerCompanies["NEO ENERGIA-PE"],
  PowerCompanies["COSERN-RN"],
  PowerCompanies["COPEL-PR"],
  PowerCompanies["ENEL-CE"],
  PowerCompanies["ENEL-RJ"],
  PowerCompanies["RGE-RS"],
  PowerCompanies["CELESC-SC"],
];

const POWER_COMPANIES_WITH_PROMPTS: string[] = [
  PowerCompanies["CEMIG-MG"],
  PowerCompanies["ENERGISA-MT"],
  PowerCompanies["ENERGISA-MS"],
  PowerCompanies["ENERGISA-SP"],
  PowerCompanies["ENERGISA-RJ"],
  PowerCompanies["ENERGISA PB-PB"],
  PowerCompanies["ENERGISA TOCANTINS-TO"],
  PowerCompanies["EQUATORIAL-GO"],
  PowerCompanies["EQUATORIAL-AL"],
  PowerCompanies["EQUATORIAL-MA"],
  PowerCompanies["EQUATORIAL-PI"],
  PowerCompanies["CPFL-SP"],
  PowerCompanies["CEEE-RS"],
  PowerCompanies["EDP-SP"],
  PowerCompanies["CPFL PIRATININGA-SP"],
  PowerCompanies["CPFL SANTA CRUZ-SP"],
  PowerCompanies["ENERGISA SUL SUDESTE-MG"],
  PowerCompanies["ENERGISA SUL SUDESTE-PR"],
  PowerCompanies["ENERGISA SUL SUDESTE-SP"],
  PowerCompanies["ENERGISA MINAS RIO-MG"],
  PowerCompanies["ENERGISA MINAS RIO-RJ"],
  PowerCompanies["ELEKTRO-SP"],
  PowerCompanies["NEO ENERGIA-PE"],
  PowerCompanies["COSERN-RN"],
  PowerCompanies["COPEL-PR"],
  PowerCompanies["ENEL-CE"],
  PowerCompanies["ENEL-RJ"],
  PowerCompanies["RGE-RS"],
  PowerCompanies["CELESC-SC"],
  PowerCompanies["EDP-ES"]
];

const PF = "PF";

const PJ = "PJ";

export {
  POWER_COMPANIES_WITH_PROMPTS,
  POWER_COMPANIES_WITH_RULES,
  Classification,
  PF,
  PJ,
};
